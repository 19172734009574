import { template as template_f380a8baacd649f0a68b90986b877659 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_f380a8baacd649f0a68b90986b877659(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
