import { template as template_ad7e73c3152d4bf19b4994b047dd8298 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ad7e73c3152d4bf19b4994b047dd8298(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
