import { template as template_64cd939707bf406aa96943f9a22ea8cb } from "@ember/template-compiler";
const FKText = template_64cd939707bf406aa96943f9a22ea8cb(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
