import { template as template_5c6b4d7e9e4b4c15b5813bc1b66274c3 } from "@ember/template-compiler";
import Component from "@glimmer/component";
export default class FKControlCustom extends Component {
    static controlType = "custom";
    static{
        template_5c6b4d7e9e4b4c15b5813bc1b66274c3(`
    <div class="form-kit__control-custom">
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
