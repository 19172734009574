import { template as template_a504e4f8b64c4ef58cd2fdd17f7d597f } from "@ember/template-compiler";
const FKLabel = template_a504e4f8b64c4ef58cd2fdd17f7d597f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
